import amplitudeClient, { AmplitudeClient } from "amplitude-js";

export let amplitude: AmplitudeClient | undefined = undefined;

export function amplitudeInitialize() {
  if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
    try {
      amplitude = amplitudeClient.getInstance();

      amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, undefined, {
        includeReferrer: true,
        includeUtm: true,
      });
    } catch {
      //pass
    }
  }
}
