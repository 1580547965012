import { createContext, useCallback, useContext, useMemo } from "react";
import { useAmplitudeQuery } from "@/generated/graphql";
import { amplitude } from "./amplitude.utils";
import { useLoggedIn } from "@/features/auth";

const AmplitudeContext = createContext<{ userAuthenticated: boolean }>({
  userAuthenticated: false,
});

export function AmplitudeProvider({ children }: React.PropsWithChildren) {
  const loggedIn = useLoggedIn();
  const [{ data }] = useAmplitudeQuery({ pause: !loggedIn });

  const userAuthenticated = useMemo(() => {
    if (!!amplitude && !!data?.me) {
      amplitude.setUserId(data.me.id);
      return true;
    }

    return false;
  }, [data?.me.id]);

  return (
    <AmplitudeContext.Provider value={{ userAuthenticated }}>
      {children}
    </AmplitudeContext.Provider>
  );
}

export function useAmplitude() {
  const { userAuthenticated } = useContext(AmplitudeContext);

  const logEvent = useCallback(
    (event: string, data?: any, callback?: () => void) => {
      if (userAuthenticated && !!amplitude) {
        amplitude.logEvent(event, data, callback, callback);
      } else callback?.();
    },
    [userAuthenticated]
  );

  const logPublicEvent = useCallback(
    (event: string, data?: any, callback?: () => void) => {
      if (!!amplitude) {
        amplitude.logEvent(event, data, callback, callback);
      } else callback?.();
    },
    []
  );

  return { logEvent, logPublicEvent };
}
