import { useEffect } from "react";
import smartlookClient from "smartlook-client";
import { useSmartlookQuery } from "@/generated/graphql";
import { useLoggedIn } from "@/features/auth";

export function Smartlook() {
  const loggedIn = useLoggedIn();
  const [{ data }] = useSmartlookQuery({ pause: !loggedIn });

  useEffect(() => {
    if (!smartlookClient.initialized()) return;

    if (!!data?.me) {
      smartlookClient.identify(data.me.id, { id: data.me.id });
      smartlookClient.record({
        forms: true,
        ips: true,
        numbers: true,
        emails: false,
        api: true,
      });
    }
  }, [data?.me.id]);

  return null;
}
