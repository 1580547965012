import smartlookClient from "smartlook-client";

export function smartlookInitialize() {
  if (process.env.NEXT_PUBLIC_SMARTLOOK_PROJECT_KEY) {
    try {
      smartlookClient.init(process.env.NEXT_PUBLIC_SMARTLOOK_PROJECT_KEY);
    } catch {
      //pass
    }
  }
}
